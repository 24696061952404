import React, { useEffect, useRef, useState, useContext,forwardRef, useImperativeHandle, } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { fabric } from './FabricExtended';
import { RgbaStringColorPicker } from "react-colorful";
import Dropdown from './Dropdown';
import ArrowIcon from './icons/ArrowIcon';
import CircleIcon from './icons/CircleIcon';
import EraseIcon from './icons/EraseIcon';
import ExportIcon from './icons/ExportIcon';
import FlopIcon from './icons/FlopIcon';
import GridIcon from './icons/GridIcon';
import HandIcon from './icons/HandIcon';
import ImageIcon from './icons/ImageIcon';
import JsonIcon from './icons/JsonIcon';
import LineIcon from './icons/LineIcon';
import PenIcon from './icons/PenIcon';
import RectIcon from './icons/RectIcon';
import UndoIcon from './icons/UndoIcon';
import StickyIcon from './icons/StickyIcon';
import TextIcon from './icons/TextIcon';
import TrashIcon from './icons/TrashIcon';
import TriangleIcon from './icons/TriangleIcon';
import RedoIcon from './icons/RedoIcon';
import GeometryIcon from './icons/GeometryIcon';
import './Whiteboard.css';
import CogIcon from './icons/CogIcon';
import { WhiteboardContext } from './WhiteboardStore';
import {initialState}  from '../../../state/ducks/room';
import apiConfig from "../../../apiConfig.json";
interface IProps {
  className?: string,
  options?: object,
  onChange?: any,
  onDeleteAll?: any,
  onErase?: any,
  onUndo?: any,
  onRedo?: any,
  onShowGrid?: any
  onFileSelect?: any
  // isAdmin?: boolean
}

const bottomMenu = [
  { title: 'Show Object Options', icon: <CogIcon /> },
  { title: 'Grid', icon: <GridIcon /> },
  { title: 'Erase', icon: <EraseIcon /> },
  { title: 'Undo', icon: <UndoIcon /> },
  { title: 'Redo', icon: <RedoIcon /> },
  { title: 'Save', icon: <FlopIcon /> },
  // { title: 'Export', icon: <ExportIcon /> },
  // { title: 'ToJson', icon: <JsonIcon /> },
  { title: 'Clear', icon: <TrashIcon /> }
];

const toolbar = [
  { title: 'Select', icon: <HandIcon /> },
  { title: 'Draw', icon: <PenIcon /> },
  { title: 'Text', icon: <TextIcon /> },
  { title: 'Sticky', icon: <StickyIcon /> },
  { title: 'Arrow', icon: <ArrowIcon /> },
  { title: 'Line', icon: <LineIcon /> },
];

let currentCanvas: any = null;
let loadFromSocket = false;
let isInteractive  = false;
let isWannaTriggerOnChange  = false;

// export const CanvasEditor = forwardRef(({ onChange, className, options }: IProps, ref) => {

// })

export const CanvasEditor = forwardRef(({ onChange, className, options,onDeleteAll,onErase,onUndo,onRedo,onShowGrid,onFileSelect}: IProps, ref) => {
  
  // const state = useSelector((state) => state);
  // console.log(state);
  const { settings } = useSelector((state:  any) => state.room);
  const parentRef = useRef<any>();
  const canvasRef = useRef<any>();
  const inputImageFileRef = useRef<any>();
  const inputJsonFileRef = useRef<any>();
  const [editor, setEditor] = useState<any>();
  // console.log('ERERERE',isAdmin);
  const isAdmin = settings.isAdmin;
  useEffect(() => {
    console.log('ttttttttttttttttttttt',canvasRef?.current);
    const canvas = new fabric.Canvas(canvasRef?.current, canvasOptions);
    currentCanvas = canvas;
    
    setEditor(canvas);
    
    const onKeydown = (e: KeyboardEvent) => {
      console.log('***********',e);
      if (!canvas) return;
      loadFromSocket = false;
      if (e.code === 'Delete' || e.keyCode === 46 || e.which === 46) {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.remove(activeObject);
        }
      }

      if ((e.ctrlKey || e.metaKey) && (e.keyCode === 67 || e.which === 67)) {
        const object = fabric.util.object.clone(canvas.getActiveObject());
        object.set("top", object.top + 5);
        object.set("left", object.left + 5);
        canvas.add(object);
      }

      if ((e.ctrlKey || e.metaKey) && (e.keyCode === 83 || e.which === 83)) {
        e.preventDefault();
        localStorage.setItem('whiteboard-cache', JSON.stringify(canvas.toDatalessJSON()));
      }

      if ((e.ctrlKey || e.metaKey) && (e.keyCode === 79 || e.which === 79)) {
        e.preventDefault();
        inputImageFileRef.current.click();
      }

      if ((e.ctrlKey || e.metaKey) && (e.keyCode === 90 || e.which === 90)) {
        e.preventDefault();
        // @ts-ignore: Unreachable code error
        canvas.undo();
      }

      if ((e.ctrlKey || e.metaKey) && (e.keyCode === 89 || e.which === 89)) {
        e.preventDefault();
        // @ts-ignore: Unreachable code error
        canvas.redo();
      }
    };

    if (parentRef && parentRef.current && canvas) {
      const data = localStorage.getItem('whiteboard-cache');

      if (data) canvas.loadFromJSON(JSON.parse(data), canvas.renderAll.bind(canvas));

      // canvas.on('mouse:down', function (event) {
      //   setShowObjOptions(canvas.getActiveObject() ? true : false)
      // });      

      if (onChange) {
        canvas.on('object:added', canvasAddedCallback);
        canvas.on('object:removed', canvasRemoveCallback);
        canvas.on('object:modified', canvasModifiedCallback);
      }

      canvas.setHeight(parentRef.current?.clientHeight || 0);
      canvas.setWidth(parentRef.current?.clientWidth || 0);
      canvas.renderAll();

      // document.addEventListener('keydown', onKeydown, false);
    }

    return () => {
      canvas.off('object:added', canvasAddedCallback);
      canvas.off('object:removed', canvasRemoveCallback);
      canvas.off('object:modified', canvasModifiedCallback);

      //canvas.off('mouse:down');
      // document.removeEventListener('keydown', onKeydown, false);
      canvas.dispose();
    };
  }, []);
  // isAdmin = true;

  // const [loadFromSocket, setLoadFromSocket] = useState(false);

  useImperativeHandle(ref, () => ({
    subChildFunction1(data:any) {
      console.log('PPPPPPPPPPPPPPP');
      console.log('wwwwwwwwwwwwwwww',editor);
      loadFromSocket = true;
    
      if (editor) {
        console.log('EEEEEEEEEEEEEEEEEEEE');
        // onToolbar('Draw');
        editor.loadFromJSON(data, (v: any) => {
          console.log(v);

     
        });
        // editor.isDrawingMode = false;
        localStorage.setItem('whiteboard-cache', JSON.stringify(data));
        console.log('sub child function 1 called',data);
      }
    },
    subChildFunction2(data:any) {
      
      isInteractive = data;
      console.log('UUUUUUUUU',isInteractive);
    },
    unDoViacmd(data:any) {
      isWannaTriggerOnChange = true;
      onBottomMenu('Undo');
    },
    deleteAllViacmd(data:any) {
      isWannaTriggerOnChange = true;
      onBottomMenu('ClearViaSocket');
    },
    eraseViacmd(data:any) {
      if (currentCanvas) {
        console.log('wwwwwwwwwwwwwwww',editor);
        isWannaTriggerOnChange = true;
        console.log("YYYYYYYYYYY",data);
        onBottomMenu('Erase',data.id);
      }
      else
      {
        console.log('ddd',currentCanvas);
      }

     
    },
    reDoViacmd(data:any) {
      isWannaTriggerOnChange = true;
      onBottomMenu('Redo');
    },
    toogleGridViacmd(data:any) {
      isWannaTriggerOnChange = true;
      onBottomMenu('Grid');

    },
    onFileChangeViaCmdCb(url:String) {
      // fabric.Image.fromURL(url, function (img:any) {
      //   console.log(img);
      //   // img.set({ width: 180, height: 180 });
      //   editor.centerObject(img);
      //   editor.add(img);
      //   // onFileSelect(url,fileType);
      //   // convertImageToBase64(url, (base64Data:any) => {
      //   //   console.log(base64Data);
      //   //   onFileSelect(base64Data,fileType);
      //   // });


      // });
      isWannaTriggerOnChange = true;
      onFileChangeViaCmd(url,'image/jpeg');

    }



  }));

  const { gstate, setGState } = useContext(WhiteboardContext);
  const { canvasOptions, backgroundImage } = gstate;

  


  const [objOptions, setObjOptions] = useState({
    stroke: '#000000', fontSize: 22, fill: 'rgba(255, 255, 255, 0.0)', strokeWidth: 3, ...options
  });

  const [colorProp, setColorProp] = useState<string>('background');

  const [showObjOptions, setShowObjOptions] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(true);

  const canvasModifiedCallback = () => {
    if (currentCanvas && !loadFromSocket) {
      console.log('##############Modified');
      onChange(currentCanvas.toDatalessJSON(),"modified");
    }
  };
  const canvasAddedCallback = () => {
    if (currentCanvas && !loadFromSocket) {
      console.log('##############Added');
      onChange(currentCanvas.toDatalessJSON(),"added");
    }
  };
  const canvasRemoveCallback = () => {
    if (currentCanvas && !loadFromSocket) {
      console.log('##############Removed');
      onChange(currentCanvas.toDatalessJSON(),"removed");
    }
  };

 
  if(!isInteractive && !isAdmin)
  {
  //  editor.isDrawingMode = true;
    if (editor) {
      editor.skipTargetFind = true;
    }
  }
  else
  {
    if (editor) {
      editor.skipTargetFind = false;
    }
  }
  
  const onToolbar = (objName: string) => {
    let objType;
    loadFromSocket = false;
    switch (objName) {
      case 'Select':
        editor.isDrawingMode = false;
        editor.discardActiveObject().renderAll();
        break;

      case 'Draw':
        if (editor) {
          editor.isDrawingMode = true;
          editor.freeDrawingBrush.width = localStorage.getItem('freeDrawingBrush.width') || 5;
          editor.freeDrawingBrush.color = localStorage.getItem('freeDrawingBrush.color') || '#000000';
        }
        break;

      case 'Text':
        editor.isDrawingMode = false;
        objType = new fabric.Textbox('Your text here', { fontSize: objOptions.fontSize });
        break;

      case 'Circle':
        editor.isDrawingMode = false;
        objType = new fabric.Circle({ ...objOptions, radius: 70 });
        break;

      case 'Rect':
        editor.isDrawingMode = false;
        objType = new fabric.Rect({ ...objOptions, width: 100, height: 100 });
        break;

      case 'Triangle':
        editor.isDrawingMode = false;
        objType = new fabric.Triangle({ ...objOptions, width: 100, height: 100 });
        break;

      case 'Arrow':
        editor.isDrawingMode = false;
        const triangle = new fabric.Triangle({
          ...objOptions,
          width: 10,
          height: 15,
          left: 235,
          top: 65,
          angle: 90
        });

        const line = new fabric.Line([50, 100, 200, 100], { ...objOptions, left: 75, top: 70 });

        objType = new fabric.Group([line, triangle]);
        break;

      case 'Line':
        editor.isDrawingMode = false;
        objType = new fabric.Line([50, 10, 200, 150], { ...objOptions, angle: 47 });
        break;
      

      case 'Sticky':
        objType = new fabric.Textbox('Your text here', {
          ...objOptions,
          backgroundColor: '#8bc34a',
          fill: '#fff',
          width: 150,
          textAlign: 'left',
          splitByGrapheme: true,
          height: 150,
          padding: 20
        });
        break;

      default:
        break;
    }

    if (objName !== 'Draw' && objName !== 'Select') {
      editor.add(objType);
      editor.centerObject(objType);
    }

    editor.renderAll();
  };

  const onBottomMenu = (actionName: string,id = null ) => {
    loadFromSocket = false;
    switch (actionName) {
      case 'Show Object Options':
        setShowObjOptions(!showObjOptions);
        break;

      case 'Export':
        const image = editor.toDataURL("image/png").replace("image/png", "image/octet-stream");
        window.open(image);
        break;

      case 'Save':
        localStorage.setItem('whiteboard-cache', JSON.stringify(editor.toDatalessJSON()));
        break;

      case 'Erase':
        loadFromSocket = true;
        if(id != null)
        {
          setTimeout(function() {
            console.log("lllllllllllll",currentCanvas);
            //currentCanvas.setActiveObject(id);
            const activeObject = id;//currentCanvas.getActiveObject();
            console.log("lllllllllllll",activeObject);
            if (activeObject) {
              currentCanvas.remove(activeObject);
            }
            if(!isWannaTriggerOnChange)
            {
              onErase(activeObject);
            }
          }, 2000);

          
        }
        else
        {
          const activeObject = editor.getActiveObject();
          if (activeObject) {
            editor.remove(activeObject);
          }
          if(!isWannaTriggerOnChange)
          {
            onErase(activeObject);
          }
        }
        
        
   
        break;

      case 'ToJson':
        const content = JSON.stringify(editor.toDatalessJSON());
        const link = document.createElement("a");
        const file = new Blob([content], { type: 'application/json' });
        link.setAttribute('download', 'whiteboard.json');
        link.href = URL.createObjectURL(file);
        document.body.appendChild(link);
        link.click();
        link.remove();
        break;

      case 'Undo':
        loadFromSocket = true;
        editor.undo();
        if(!isWannaTriggerOnChange)
        {
          onUndo();
        }
        break;

      case 'Redo':
        loadFromSocket = true;
        editor.redo();
        if(!isWannaTriggerOnChange)
        {
          onRedo();
        }
        break;

      case 'Grid':
       
        setShowGrid(!showGrid);
        if(!isWannaTriggerOnChange)
        {
          onShowGrid(showGrid);
        }
        break;

      case 'Clear':
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure to reset the whiteboard?')) {
          loadFromSocket = true;
          localStorage.removeItem('whiteboard-cache');
          editor.clearHistory();
          editor.clear();
          editor.isDrawingMode = false;
          editor.discardActiveObject().renderAll();
          if(!isWannaTriggerOnChange)
          {
            onDeleteAll();
          }
        }
        break;
      case 'ClearViaSocket':
        loadFromSocket = true;
        localStorage.removeItem('whiteboard-cache');
        editor.clearHistory();
        editor.clear();
        if(!isWannaTriggerOnChange)
        {
          onDeleteAll();
        }
        // eslint-disable-next-line no-restricted-globals
       

      default:
        break;
    }
  };

  const onFileChange = (e: any) => {
    console.log(e.target.files.length);
    loadFromSocket = false;
    if (e.target.files.length < 1) return;

    const inputFileName = e.target.name;
    const file = e.target.files[0];

    let formdata = new FormData();
    formdata.append("file", file);
    let replacedString = "";
    // let requestOptions = {
    //   method: 'POST',
    //   body: formdata,
    //   redirect: 'follow'
    // };
    // let replacedString = "";
    // fetch("https://apiapp.edumation.in/api/CollegeOnlineClass/uploadFile", requestOptions)
    //   .then(response => { if(response.status == 200)
    //   {
    //     return response.json();
    //   }
    //   else if(response.status == 401)
    //   {
    //     console.log('Un Authorised');
    //   }})
    //   .then(data => {      if(data)
    //   {
    //     console.log('tttttttttt',data.data);
    //     let ipUrl = data.data;
    //     replacedString = ipUrl.replace("http://172.16.14.6:300", "https://apiapp.edumation.in");
    //     // dispatch(chatActions.send(replacedString));
    //     // setRoomList(data.data);
    //   }})
    //   .catch(error => console.log('error', error));

    const xhr = new XMLHttpRequest();
    xhr.open('POST', apiConfig.BASE_URL+'CollegeOnlineClass/uploadFile', true);
    
    // You can set additional headers if needed
    // xhr.setRequestHeader('Authorization', 'Bearer YOUR_ACCESS_TOKEN');
    
    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          console.log('File uploaded successfully:', xhr.responseText);
          const jsonResponse = JSON.parse(xhr.responseText);
          let ipUrl = jsonResponse.data;
          replacedString = ipUrl.replace("http://172.16.14.6:300", "https://apiapp.edumation.in");
          const url = replacedString;//URL.createObjectURL(file);
          console.log("BBBBBBBBBBBBBBBB",url);
          if (inputFileName === 'json') {
            fetch(url).then(r => r.json())
              .then(json => {
                editor.loadFromJSON(json, (v: any) => {
                  console.log(v);
                });
              });
          }
          else {
            const fileType = file.type;
            loadFromSocket = true;
            if (fileType === 'image/png' || fileType === 'image/jpeg') {
              console.log(url);
              fabric.Image.fromURL(url, function (img:any) {
                console.log(img);
                // img.set({ width: 180, height: 180 });
                editor.centerObject(img);
                editor.add(img);
                const content = JSON.stringify(editor.toDatalessJSON());
                localStorage.setItem('whiteboard-cache', content);
                console.log("fffffffffffffffffff",content);
                onFileSelect(content);
                
                // convertImageToBase64(url, (base64Data:any) => {
                //   console.log(base64Data);
                //   onFileSelect(base64Data,fileType);
                // });
        
      
              });
            }

          }
          if (fileType === 'image/svg+xml') {
            fabric.loadSVGFromURL(url, function (objects:any, options:any) {
              let svg = fabric.util.groupSVGElements(objects, options);
              svg.scaleToWidth(180);
              svg.scaleToHeight(180);
              editor.centerObject(svg);
              editor.add(svg);
            });
          }
        }
        else {
          console.error('File upload failed:', xhr.status, xhr.statusText);
        }
      }
    };
    
    xhr.send(formdata);



    const fileType = file.type;
    

      
    
  };
  const convertImageToBase64 = (imgUrl:any, callback:any) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx?.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl);
    };
    image.src = imgUrl;
  };

  const onFileChangeViaCmd = (url:any,fileType:any) => {

    loadFromSocket = true;
    //let file = new Blob([base64ImageData], { type: fileType });
    let canvas = canvasRef?.current;
    if(!editor)
    {
      const canvas = new fabric.Canvas(canvasRef?.current, canvasOptions);
      currentCanvas = canvas;
    
      setEditor(canvas);
      console.log("dddddddddddddddddd",editor);
    }
    console.log("ggggggggggggggggg",editor);

   
    //const url = //URL.createObjectURL(file);

    
    if (fileType === 'image/png' || fileType === 'image/jpeg') {
      fabric.Image.fromURL(url, function (img:any) {
        // img.set({ width: 180, height: 180 });
        currentCanvas.centerObject(img);
        currentCanvas.add(img);
      });
    }

    if (fileType === 'image/svg+xml') {
      fabric.loadSVGFromURL(url, function (objects:any, options:any) {
        let svg = fabric.util.groupSVGElements(objects, options);
        svg.scaleToWidth(180);
        svg.scaleToHeight(180);
        canvas.centerObject(svg);
        canvas.add(svg);
      });
    }
    
  };

  const onRadioColor = (e: any) => {
    setColorProp(e.target.value);
  };

  const onColorChange = (value: any) => {
    const activeObj = editor.getActiveObject();

    if (editor.isDrawingMode) {
      editor.freeDrawingBrush.color = value;
      localStorage.setItem('freeDrawingBrush.color', value);
    }
    if (activeObj) {
      activeObj.set(colorProp, value);
      const ops = { ...objOptions, [colorProp]: value };
      setObjOptions(ops);
      editor.renderAll();
    }
    else {
      if (colorProp === 'backgroundColor') {
        editor.backgroundColor = value;
        editor.renderAll();
      }
    }
  };

  const onOptionsChange = (e: any) => {
    let val = e.target.value;
    const name = e.target.name;
    const activeObj = editor.getActiveObject();

    if (editor.isDrawingMode && name === 'strokeWidth') {
      editor.freeDrawingBrush.width = val;
      localStorage.setItem('freeDrawingBrush.width', val);
    }

    if (activeObj) {
      val = isNaN(val) ? val : +val;
      activeObj.set(name, val);

      const ops = { ...objOptions, [name]: val };
      setObjOptions(ops);
      editor.renderAll();
    }
  };

  const onZoom = (e: any) => {
    editor.zoomToPoint(new fabric.Point(editor.width / 2, editor.height / 2), +e.target.value);
    const units = 10;
    const delta = new fabric.Point(units, 0);
    editor.relativePan(delta);

    e.preventDefault();
    e.stopPropagation();
  };

  const onLoadImage = () => {
    inputImageFileRef.current.click();
  };

  const onLoadFromJson = () => {
    inputJsonFileRef.current.click();
  };

  return (<div className={'h-100 whiteboard ' + className}
    style={{ backgroundImage: showGrid ? backgroundImage : '' }}
    ref={parentRef}>

    {showObjOptions && <div className='left-menu'>
      <div className='bg-white d-flex align-center justify-between shadow br-7'>
        <label>Font size</label>
        <input type="number" min="1" name='fontSize' onChange={onOptionsChange} defaultValue="22" />
      </div>

      <div className='bg-white d-flex align-center justify-between shadow br-7'>
        <label>Stroke</label>
        <input type="number" min="1" name='strokeWidth' onChange={onOptionsChange} defaultValue="3" />
      </div>

      <div className='bg-white d-flex flex-column shadow br-7'>
        <div className='d-flex align-end mb-10'>
          <input className='mr-10' type="radio" onChange={onRadioColor} name="color" defaultValue="backgroundColor" />
          <label htmlFor='backgroundColor'>background</label>
        </div>
        <div className='d-flex align-end mb-10'>
          <input className='mr-10' type="radio" onChange={onRadioColor} id="stroke" name="color" defaultValue="stroke" />
          <label htmlFor='stroke'>stroke</label>
        </div>

        <div className='d-flex align-end mb-10'>
          <input className='mr-10' type="radio" onChange={onRadioColor} id="fill" name="color" defaultValue="fill" />
          <label htmlFor='fill'>fill</label>
        </div>

        <RgbaStringColorPicker onChange={onColorChange} />
      </div>
    </div>}

    { isAdmin && <div className='d-flex justify-center align-center' style={{ position: 'fixed', top: '10px', left: 210, zIndex: 9999 }}>
      <div className='bg-white d-flex justify-center align-center shadow br-7'>
        <div className='d-flex align-center bg-white br-7 shadow'>
          {toolbar.map(item => <button key={item.title} onClick={() => { onToolbar(item.title) }} title={item.title}>{item.icon}</button>)}
          <Dropdown title={<GeometryIcon />}>
            <button onClick={() => { onToolbar('Circle') }} title="Add Circle"><CircleIcon /></button>
            <button onClick={() => { onToolbar('Rect') }} title="Add Rectangle"><RectIcon /></button>
            <button onClick={() => { onToolbar('Triangle') }} title="Add Triangle"><TriangleIcon /></button>
          </Dropdown>
          <button onClick={() => { onLoadImage() }} title="Load Image"><ImageIcon /></button>
          <button onClick={() => { onLoadFromJson() }} title="Load From Json"><JsonIcon /></button>
          {bottomMenu.map(item => <button key={item.title} onClick={() => { onBottomMenu(item.title) }} title={item.title}>{item.icon}</button>)}
        </div>

        <select className='d-flex align-center bg-white br-7 shadow border-0 pr-1 pl-1' onChange={onZoom} defaultValue="1">
          <option value="2">200%</option>
          <option value="1.5">150%</option>
          <option value="1">100%</option>
          <option value="0.75">75%</option>
          <option value="0.50">50%</option>
          <option value="0.25">25%</option>
        </select>

        <input ref={inputImageFileRef} type="file" name='image' onChange={onFileChange} accept="image/svg+xml, image/gif, image/jpeg, image/png" hidden />
        <input ref={inputJsonFileRef} type="file" name='json' onChange={onFileChange} accept="application/json" hidden />
   
      </div>
    </div>
    }
    {  isInteractive == true ? ( <div className='d-flex justify-center align-center' style={{ position: 'fixed', top: '10px', left: 210, zIndex: 9999 }}>
      <div className='bg-white d-flex justify-center align-center shadow br-7'>
        <div className='d-flex align-center bg-white br-7 shadow'>
          {toolbar.map(item => <button key={item.title} onClick={() => { onToolbar(item.title) }} title={item.title}>{item.icon}</button>)}
          <Dropdown title={<GeometryIcon />}>
            <button onClick={() => { onToolbar('Circle') }} title="Add Circle"><CircleIcon /></button>
            <button onClick={() => { onToolbar('Rect') }} title="Add Rectangle"><RectIcon /></button>
            <button onClick={() => { onToolbar('Triangle') }} title="Add Triangle"><TriangleIcon /></button>
          </Dropdown>
          <button onClick={() => { onLoadImage() }} title="Load Image"><ImageIcon /></button>
          <button onClick={() => { onLoadFromJson() }} title="Load From Json"><JsonIcon /></button>
          {bottomMenu.map(item => <button key={item.title} onClick={() => { onBottomMenu(item.title) }} title={item.title}>{item.icon}</button>)}
        </div>

        <select className='d-flex align-center bg-white br-7 shadow border-0 pr-1 pl-1' onChange={onZoom} defaultValue="1">
          <option value="2">200%</option>
          <option value="1.5">150%</option>
          <option value="1">100%</option>
          <option value="0.75">75%</option>
          <option value="0.50">50%</option>
          <option value="0.25">25%</option>
        </select>

        <input ref={inputImageFileRef} type="file" name='image' onChange={onFileChange} accept="image/svg+xml, image/gif, image/jpeg, image/png" hidden />
        <input ref={inputJsonFileRef} type="file" name='json' onChange={onFileChange} accept="application/json" hidden />
   
      </div>
    </div> ) : null
    }
    <canvas ref={canvasRef} className='canvas' />

    {/* { (isAdmin || isInteractive) &&  <div className='w-100 bottom-menu'>
      <div className='d-flex align-center bg-white br-7 shadow pr-1 pl-1'>feedback</div>

      <div className='d-flex align-center bg-white br-7 shadow'>
        {toolbar.map(item => <button key={item.title} onClick={() => { onToolbar(item.title) }} title={item.title}>{item.icon}</button>)}
        <Dropdown title={<GeometryIcon />}>
          <button onClick={() => { onToolbar('Circle') }} title="Add Circle"><CircleIcon /></button>
          <button onClick={() => { onToolbar('Rect') }} title="Add Rectangle"><RectIcon /></button>
          <button onClick={() => { onToolbar('Triangle') }} title="Add Triangle"><TriangleIcon /></button>
        </Dropdown>
        <button onClick={() => { onLoadImage() }} title="Load Image"><ImageIcon /></button>
        <button onClick={() => { onLoadFromJson() }} title="Load From Json"><JsonIcon /></button>
        {bottomMenu.map(item => <button key={item.title} onClick={() => { onBottomMenu(item.title) }} title={item.title}>{item.icon}</button>)}
      </div>

      <select className='d-flex align-center bg-white br-7 shadow border-0 pr-1 pl-1' onChange={onZoom} defaultValue="1">
        <option value="2">200%</option>
        <option value="1.5">150%</option>
        <option value="1">100%</option>
        <option value="0.75">75%</option>
        <option value="0.50">50%</option>
        <option value="0.25">25%</option>
      </select>

      <input ref={inputImageFileRef} type="file" name='image' onChange={onFileChange} accept="image/svg+xml, image/gif, image/jpeg, image/png" hidden />
      <input ref={inputJsonFileRef} type="file" name='json' onChange={onFileChange} accept="application/json" hidden />
    </div> */}
    {/* } */}
  </div>);
});