import React,{forwardRef, useImperativeHandle, useRef} from 'react';
import { WhiteboardStore } from './WhiteboardStore';
import { CanvasEditor } from './CanvasEditor';

// export function Whiteboard(props: any) {
//   return <WhiteboardStore>
//     <CanvasEditor {...props} />
//   </WhiteboardStore>
// }
type SecondChildComponentType = {
  subChildFunction1: (data: any) => void; // Define the function's signature
  subChildFunction2: (data: any) => void; // Define the function's signature
  unDoViacmd: (data: any) => void; // Define the function's signature
  deleteAllViacmd: (data: any) => void; // Define the function's signature
  eraseViacmd: (data: any) => void; // Define the function's signature
  reDoViacmd: (data: any) => void; // Define the function's signature
  toogleGridViacmd: (data: any) => void; // Define the function's signature
  onFileChangeViaCmdCb: (data: any,data2:any) => void; // Define the function's signature
};
export const Whiteboard = forwardRef((props, ref) => {
  const secondChildRef = useRef<SecondChildComponentType | null>(null);
  useImperativeHandle(ref, () => ({
    childFunction1(data:any) {
      console.log('child function 1 called',secondChildRef?.current);
      secondChildRef?.current?.subChildFunction1(data);
    },
    childFunction2(data:any) {
      console.log('child function 2 called');
      secondChildRef?.current?.subChildFunction2(data);
    },

    unDoViacmd(data:any) {
      secondChildRef?.current?.unDoViacmd(data);
    },
    deleteAllViacmd(data:any) {
      secondChildRef?.current?.deleteAllViacmd(data);
    },
    eraseViacmd(data:any) {
      secondChildRef?.current?.eraseViacmd(data);
    },
    reDoViacmd(data:any) {
      secondChildRef?.current?.reDoViacmd(data);
    },
    toogleGridViacmd(data:any) {
      console.log('child function 2 called');
      secondChildRef?.current?.toogleGridViacmd(data);
    },
    onFileChangeViaCmdCb(base64Data:any,type:any) {
      console.log('child function 2 called');
      secondChildRef?.current?.onFileChangeViaCmdCb(base64Data,type);
    },

  }));

  return <WhiteboardStore>
    <CanvasEditor {...props} ref={secondChildRef}/>
  </WhiteboardStore>;
});
// export default Whiteboard;